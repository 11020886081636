export function siteNameText(breakpoint) {
  let style = ''
  if (breakpoint.lgAndUp) {
    // Desktops & HR
    style = `font-weight-light text-h5`
  } else if (breakpoint.mdAndUp) {
    // Tablets Pro & laptops
    style = `font-weight-light text-h4`
  } else if (breakpoint.smAndUp) {
    // Tablets
    style = `font-weight-light text-h5`
  } else {
    // Mobiles
    style = `font-weight-medium text-h6 text-truncate`
  }
  return `${style} av-card-btn`
}

export function truncate(txt, length = 16) {
  let res = txt
  if (txt && txt.length > length) {
    res = `${res.substring(0, length - 3)}...`
  }
  return res
}

export function isEmail(txt) {
  return txt.indexOf('@') !== -1
}

export function resolvedAreaName(val) {
  const i = val.indexOf('@')
  return i !== -1 ? val.substr(0, i) : val
}
