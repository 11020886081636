<template>
  <v-container fluid>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-checkbox v-model="checked" :color="checkColor" v-on="on">
          <template v-slot:label>
            <span class="text-decoration-underline black--text"
              >Acepto las políticas de ingreso</span
            >
          </template>
        </v-checkbox>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">Acuerdo </v-card-title>

        <v-card-text>
          <h3 class="mt-5 mb-2 text-subtitle-1">
            Acepto que no asistiré si presento al menos dos de los posibles
            síntomas del COVID-19:
          </h3>

          <ul class="text-subtitle-2">
            <li>Tos, estornudos o escurrimiento nasal</li>
            <li>Fiebre</li>
            <li>Dolor de cabeza</li>
          </ul>

          <h3 class="mt-5 mb-2 text-subtitle-1">
            Que pueden ir acompañados de:
          </h3>
          <ul class="text subtitle-2">
            <li>Dolor de garganta o,</li>
            <li>Diarrea</li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn color="identity" outlined @click="check">
            Acepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters('requests', ['draft']),
    checkColor: function() {
      return this.checked ? 'identity' : 'red accent-3'
    },
    checked: {
      set: function(val) {
        this.saveDraft({ key: 'terms', value: val })
        this.updateValidSection({ section: 'terms', value: !!val })
      },
      get: function() {
        return this.draft.terms
      }
    }
  },
  methods: {
    ...mapActions('requests', ['saveDraft', 'updateValidSection']),
    check() {
      this.dialog = false
      this.checked = true
    }
  }
}
</script>
