<template>
  <div>
    <h1 class="text-h6 text-md-h4 mb-3" :class="fontWeightLightMd">
      Selecciona el día
    </h1>
    <v-date-picker
      :year-icon="checkIcon"
      reactive
      :full-width="fullWidth"
      v-model="dates"
      :multiple="multiple"
      color="identity"
      header-color="identity"
      elevation="2"
      :show-current="isEmpty"
      locale="es-mx"
      :landscape="landscape"
      :min="minDate()"
      :max="maxDate()"
      :title-date-format="dateFormatted"
      @touchstart.native.stop
      @touchmove.native.stop
      @touchend.native.stop
    ></v-date-picker>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Calendar',
  data: () => ({
    menu: false,
    landscape: false,
    multiple: false
  }),
  computed: {
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    },
    isEmpty() {
      return !this.dates
    },
    fullWidth() {
      return this.$vuetify.breakpoint.smAndUp ? false : true
    },
    checkIcon() {
      return this.isEmpty ? 'mdi-calendar-blank' : 'mdi-calendar-check'
    },
    ...mapGetters('requests', ['draft']),
    dates: {
      get: function() {
        return this.draft.date
      },
      set: function(value) {
        this.saveDraft({ key: 'date', value })
        this.updateValidSection({ section: 'date', value: !!value })
      }
    }
  },
  methods: {
    ...mapActions('requests', ['saveDraft', 'updateValidSection']),
    minDate() {
      return moment().format('YYYY-MM-DD')
    },
    maxDate() {
      return moment()
        .add(30, 'days')
        .format('YYYY-MM-DD')
    },
    dateFormatted() {
      return !this.isEmpty ? moment(this.dates).format('dddd, D MMMM') : ''
    }
  },
  created() {
    moment.locale('es-mx')
  }
}
</script>

<style lang="scss">
.v-date-picker-header__value button {
  font-weight: 500;
  font-size: 1.2rem;
}
.v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
  // Header input
  font-weight: 400 !important;
  font-size: 1.5rem;
}
.v-date-picker-table--date td {
  width: 35px;
}
.v-date-picker-table .v-btn {
  // day btn
  font-size: 22px;
}
.v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
  text-transform: uppercase;
}
.v-btn--icon.v-size--default {
  width: 48px;
  height: 48px;
}

.theme--light.v-btn.v-btn--icon {
  color: black;
}
</style>
